import { useMount } from 'ahooks';
import { useLocation, useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location?.state;

  useMount(() => {
    if (state?.url) {
      window.location.href = state.url;
    } else {
      navigate(-1);
    }
  });

  return null;
}

export default Redirect;
