import { useState } from 'react';
import { Descriptions, Divider, Table } from 'antd';

import { CALL_TYPE } from '@constants/consultation';
import { toDateFormat, toPhoneNumberFormat } from '@helper/formatHelper';
import { useGetConsultation } from '@api/consultation';
import useConsultationStore from '@store/useConsultationStore';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';

function DescriptionNonMember() {
  const [page, setPage] = useState(1);

  const currentMember = useConsultationStore((state) => state.currentMember);

  const { data: consultation } = useGetConsultation({
    page,
    customerPhone: currentMember?.mobile,
  });

  const { [CODE_KEY.CONSULTATION_CATEGORY]: consultCategories } = useCodeStore(
    (state) => state.code,
  );

  const handleSetPage = (no) => {
    setPage(no);
  };

  const columns = [
    {
      title: '카테고리',
      dataIndex: 'category',
      render: (category) => consultCategories?.[category] || '-',
    },
    { title: '회원명', dataIndex: 'customerName' },
    { title: '내용', dataIndex: 'content', width: 500 },
    {
      title: '날짜',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
    },
    { title: '상담사', dataIndex: 'agentName' },
    {
      title: '수신 / 발신',
      dataIndex: 'callType',
      render: (callType) => CALL_TYPE?.[callType] || '',
    },
  ];

  return (
    <div className="h-screen">
      <h5>비회원 상담 내역</h5>
      <Divider />
      <Descriptions bordered className="w-[50%]">
        <Descriptions.Item label="전화번호" labelStyle={{ width: '30%' }}>
          {toPhoneNumberFormat(currentMember?.mobile)}
        </Descriptions.Item>
      </Descriptions>
      <div className="my-5">
        <Table
          rowKey="createdDate"
          dataSource={consultation?.list || []}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: consultation?.total || 0,
            current: consultation?.pageNum || 1,
            onChange: (current) => handleSetPage(current),
          }}
        />
      </div>
    </div>
  );
}

export default DescriptionNonMember;
