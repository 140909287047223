import { Navigate, useLocation } from 'react-router-dom';

import storage from '@utils/Storage';

function withPrivate(Component) {
  return (props) => {
    const location = useLocation();

    if (!storage.hasSession()) {
      return <Navigate to="/login" replace state={{ from: location }} />;
    }
    return <Component {...props} />;
  };
}

export default withPrivate;
