import { Button, Descriptions, message } from 'antd';

import { toPhoneNumberFormat } from '@helper/formatHelper';
import { onCopyToClipboard } from '@helper/uiHelper';
import useConsultationStore from '@store/useConsultationStore';

import MemberDetail from './MemberDetail';

function DescriptionMember() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  const handleCopy = () => {
    onCopyToClipboard(`${currentMember?.memberId} / ${currentMember?.mobile}`);
    message.success('클립보드에 복사 되었습니다');
  };

  return (
    <div>
      <Descriptions
        bordered
        title={
          <>
            회원정보
            <Button className="ml-[20px]" onClick={handleCopy}>
              클립보드에 복사
            </Button>
          </>
        }
        column={2}
        className="mb-10"
        size="middle"
      >
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원구분">
          <span className="text-secondary font-semibold">
            {`${
              currentMember?.memberTypeNm ||
              currentMember?.memberTypes?.[0]?.memberTypeNm
            }`}
          </span>
          <span>
            {`${
              currentMember?.courseNm
                ? ` / ${currentMember?.courseNm} ${currentMember?.gradeNm}`
                : ''
            }`}
          </span>
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="회원번호">
          {currentMember?.memberId}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="전화번호">
          {toPhoneNumberFormat(currentMember?.mobile || '-')}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="이름">
          {currentMember?.memberName}
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 'fit' }} label="학교">
          {currentMember?.school
            ? `${currentMember.school?.region} ${currentMember.school?.schoolNm}`
            : '-'}
        </Descriptions.Item>
      </Descriptions>

      <MemberDetail currentMember={currentMember} />
    </div>
  );
}

export default DescriptionMember;
