import { useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';

import { isMemberNumber, isPhoneNumber } from '@helper/validateHelper';
import { useGetMembers } from '@api';
import { useOverlayContext } from '@context/OverlayContext';
import useConsultationStore from '@store/useConsultationStore';

function FormSearchMember() {
  const navigate = useNavigate();
  const { openAlert } = useOverlayContext();

  const { key } = useParams();
  const [form] = Form.useForm();

  const [payload, setPayload] = useState({ searchKey: '', searchValue: '' });
  const isConsultationDetailPage = useMatch('/consultation/:id');

  const { setCurrentMember, setMembers } = useConsultationStore(
    (state) => state,
  );
  const { data: originMembers } = useGetMembers(payload);

  const validateMemberRule = (_, value) => {
    if (!isMemberNumber(value) && !isPhoneNumber(value)) {
      return Promise.reject(new Error('회원ID 또는 연락처를 입력해주세요.'));
    }
    return Promise.resolve();
  };

  const handleGetMembers = ({ searchValue }) => {
    const searchKey = isPhoneNumber(searchValue) ? 'phone' : 'id';
    setPayload({
      searchKey,
      searchValue,
      query: Date.now(),
    });
  };

  const handleSubmit = (formValue) => {
    const { errors } = form.getFieldsError();
    // 데이터가 없다면
    if (!isEmpty(errors) || isEmpty(formValue)) {
      return;
    }
    // 상세 페이지가 아니면 페이지 이동
    const mobile = formValue?.mobile || formValue;
    if (!isConsultationDetailPage) {
      navigate(`/consultation/${mobile}`);
    } else {
      handleGetMembers({ searchValue: mobile });
    }
  };

  // 일치하는 회원이 없음
  useEffect(() => {
    if (Number(originMembers?.total) === 0 && payload.searchKey === 'id') {
      openAlert(`일치하는 회원 ID가 없습니다. (${payload.searchValue})`);
    }
  }, [openAlert, originMembers?.total, payload.searchKey, payload.searchValue]);

  // 회원 정보가 있음
  useEffect(() => {
    if (Number(originMembers?.total) > 0) {
      setMembers(originMembers);
    }
  }, [originMembers, originMembers?.total, setMembers]);

  // 조회결과 비회원인 경우 휴대폰 번호만 넣기
  useEffect(() => {
    if (Number(originMembers?.total) === 0 && payload.searchKey === 'phone') {
      setCurrentMember({
        mobile: payload.searchValue,
        memberName: '',
        memberId: '',
      });
    }
  }, [originMembers, payload.searchKey, payload.searchValue, setCurrentMember]);

  useEffect(() => {
    if (!isConsultationDetailPage) {
      setPayload({ searchKey: '', searchValue: '' });
    }
  }, [isConsultationDetailPage]);

  // 상세 페이지 진입 시 목록 조회
  useEffect(() => {
    form.setFieldsValue({ userInfo: key || '' });
    handleGetMembers({ searchValue: key });
  }, [form, key]);

  return (
    <Form form={form} className="flex justify-center mr-20">
      <Form.Item
        label="회원검색"
        className="ml-10 flex items-center mb-0"
        colon={false}
        name="userInfo"
        rules={[{ validator: validateMemberRule }]}
        normalize={(value, prevValue) => {
          if (value === prevValue) return value;
          return value.replace(/[^0-9]/g, '').trim();
        }}
      >
        <Input.Search
          onSearch={handleSubmit}
          placeholder="회원ID 또는 연락처(-생략)"
          allowClear
          enterButton="조회"
          className="w-[300px]"
        />
      </Form.Item>
    </Form>
  );
}

export default FormSearchMember;
