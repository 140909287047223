import { Table } from 'antd';

import { useGetSubscribeHistory } from '@api/consultation';
import useConsultationStore from '@store/useConsultationStore';

function TabLearningHistory() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  const { data: subscribe } = useGetSubscribeHistory(currentMember);

  const columns = [
    { title: '구독상품명', dataIndex: 'goodsName', width: 150 },
    { title: '상태', dataIndex: 'contractStatus', width: 80 },
    { title: '정기결제일', dataIndex: 'subscribeDay', width: 80 },
    // { title: '담당선생님(ID)', dataIndex: 'teacher' }, //TODO
    { title: '시작일자', dataIndex: 'serviceStartDate', width: 150 },
    { title: '종료일자', dataIndex: 'serviceEndDate', width: 150 },
  ];

  return (
    <Table
      rowKey="orderId"
      total={subscribe?.length || 0}
      dataSource={subscribe || []}
      columns={columns}
    />
  );
}

export default TabLearningHistory;
