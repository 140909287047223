import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import useAccountStore, { useAccountActions } from '@store/useAccountStore';

import {
  catsAgentSelector,
  useMonitorSocketStore,
} from './store/useMonitorSocketStore';
import CallCategory from './components/CallCategory';
import useMonitorSocket from './hooks/useMonitorSocket';
import WaitingQueue from './components/WaitingQueue';

function CtiPanel() {
  const agents = useMonitorSocketStore(catsAgentSelector);
  const { initMonitorSocket } = useMonitorSocket();
  const agentNumber = useAccountStore((state) => state.agentNumber);
  const { fetchAccount } = useAccountActions();

  useEffect(() => {
    if (agentNumber) {
      initMonitorSocket(agentNumber);
    }
  }, [initMonitorSocket, agentNumber, fetchAccount]);

  const readyAgents = useMemo(() => {
    return Object.values(agents)
      .filter((value) => value.readyTime)
      .sort(
        (itemA, itemB) =>
          dayjs(itemA.readyTime, 'HH:mm:ss').valueOf() -
          dayjs(itemB.readyTime, 'HH:mm:ss').valueOf(),
      )
      .slice(0, 4)
      .map((readyAgent, index) => ({
        ...readyAgent,
        key: `${readyAgent.eventMember.agent}-${readyAgent.readyTime}-${
          index + 1
        }`,
      }));
  }, [agents]);

  const LIVE_CATEGORY = [
    { label: '수신 대기', key: 'ready_cnt' },
    { label: '수신 거부', key: 'paused_cnt' },
    { label: '통화중', key: 'busy_cnt' },
    { label: '대기 콜', key: 'wait_call' },
    { label: '수신 성공', key: 'suc_call' },
    { label: '수신 실패', key: 'fail_call' },
    { label: '콜처리율', key: 'ratio' },
  ];

  return (
    <>
      {LIVE_CATEGORY.reverse().map(({ label, key }, index) => (
        <CallCategory
          key={`category_${index}`}
          label={label}
          field={key}
          index={index}
        />
      ))}
      <WaitingQueue readyAgents={readyAgents} agentNumber={agentNumber} />
    </>
  );
}

export default CtiPanel;
