import { create } from 'zustand';
import produce from 'immer';
import { isEmpty } from 'lodash';

const initialValue = {
  members: {},
  currentMember: {},
};

const useConsultationStore = create((set) => ({
  ...initialValue,
  clear: () => set(initialValue),
  setMembers: (members) =>
    set(
      produce((state) => {
        // 일단 한번 초기화
        state.members = {};
        if (!isEmpty(members)) {
          // 타입별로 row 분리
          state.members = {
            ...members,
            list: members?.list.reduce((pre, current) => {
              current.memberTypes?.forEach(({ memberTypeCd, memberTypeNm }) => {
                pre.push({
                  ...current,
                  key: `${current.memberId}/${memberTypeCd}`,
                  memberType: memberTypeCd,
                  memberTypeNm,
                });
              });
              return pre;
            }, []),
          };
        }
      }),
    ),
  setCurrentMember: (member) =>
    set(
      produce((state) => {
        state.currentMember = member;
      }),
    ),
}));

export default useConsultationStore;
