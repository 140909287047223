import { UserOutlined } from '@ant-design/icons';
import { ConsultationPage, ConsultationPageDeail } from '@pages';

const consultationConfig = [
  {
    path: 'consultation',
    element: ConsultationPage,
    option: {
      label: '상담 내역',
      icon: <UserOutlined />,
      key: 'consultation',
    },
  },
  {
    path: 'consultation/:key',
    element: ConsultationPageDeail,
    option: {
      label: '상담 상세',
    },
  },
];

export default consultationConfig;
