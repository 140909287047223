import { LogoutOutlined } from '@ant-design/icons';
import { FormSearchMember } from '@components/form';
import { useAuthContext } from '@context/AuthContext';
import { CallerPanel } from '@feature/cti';
import { isDevelopment, isLocal } from '@utils/systemPhaseUtils';

function Gnb() {
  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };
  return (
    <div className="flex flex-1 h-12 align-middle">
      <FormSearchMember />
      {(isLocal() || isDevelopment()) && <CallerPanel />}
      <div className="mr-[50px] ml-auto pt-[5px]">
        <LogoutOutlined
          onClick={handleLogout}
          className="text-2xl text-secondary"
        />
      </div>
    </div>
  );
}

export default Gnb;
