import { createContext, useContext, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useMatch } from 'react-router-dom';

import useCallSocket from '@feature/cti/hooks/useCallSocket';
import storage from '@utils/Storage';
import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import {
  AGENT_SOCKET_STATUS,
  CALL_EVENT,
  DEVICE_STATUS,
} from '@feature/cti/data/constants';
import { callSocketActions } from '@feature/cti/actions/callSocketActions';
import { usePrevious } from 'ahooks';

const CallerContext = createContext();

function CallerProvider({ children }) {
  const { initCallSocket } = useCallSocket();
  const isLoginPage = useMatch('/login');
  const [{ status, connected }, device, latestAgentCallEvent] =
    useCallSocketStore(
      (state) => [state.queue, state.device, state.latestAgentCallEvent],
      shallow,
    );
  const prevDevice = usePrevious(device);

  // useEffect(() => {
  //   if (storage.hasSession() && !isLoginPage) {
  //     initCallSocket();
  //   }
  // }, [initCallSocket, isLoginPage]);

  // useEffect(() => {
  //   if (
  //     connected === false &&
  //     status === AGENT_SOCKET_STATUS.READY_TO_QUEUE_CONNECT
  //   ) {
  //     return callSocketActions.qLogin();
  //   }
  // }, [status, connected]);

  // // TODO 전화 울림 등 call 관련 처리
  // useEffect(() => {
  //   if (device) {
  //     console.log('prev device', prevDevice);
  //     console.log('current device', device);
  //     switch (device.status) {
  //       case DEVICE_STATUS.RINGING:
  //         break;
  //       case DEVICE_STATUS.INUSE:
  //         break;
  //       case DEVICE_STATUS.NOT_INUSE:
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [device, prevDevice]);

  // useEffect(() => {
  //   console.log('current latestAgentCallEvent', latestAgentCallEvent);
  //   if (latestAgentCallEvent) {
  //     switch (latestAgentCallEvent) {
  //       case CALL_EVENT.RINGING:
  //         break;
  //       case CALL_EVENT.ANSWER:
  //         break;
  //       case CALL_EVENT.HANGUP:
  //         callSocketActions.qUnpause();
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [latestAgentCallEvent]);

  return <CallerContext.Provider value={{}}>{children}</CallerContext.Provider>;
}

function useCallerContext() {
  return useContext(CallerContext);
}

export { CallerProvider, useCallerContext };
