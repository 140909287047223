import { Table } from 'antd';

import { useGetPaymentHistory } from '@api/consultation';
import { toMoneyFormat } from '@helper/formatHelper';
import useConsultationStore from '@store/useConsultationStore';
import { useState } from 'react';

function TabPaymentHistory() {
  const currentMember = useConsultationStore((state) => state.currentMember);
  const [page, setPage] = useState(1);

  const { data: payment } = useGetPaymentHistory({
    memberId: currentMember.memberId,
    page,
  });

  const handleSetPage = (num) => {
    console.log(num);
    setPage(num);
  };

  const columns = [
    { title: '회원 이름', dataIndex: 'memberName' },
    { title: '회원 ID', dataIndex: 'memberNo' },
    { title: '구분', dataIndex: 'goodsKindCode' },
    { title: '주문 ID', dataIndex: 'orderId', width: 150 },
    { title: '결제일시', dataIndex: 'paymentDate' },
    { title: '상태', dataIndex: 'orderStepCode' },
    { title: '결제금액', dataIndex: 'paymentAmount', render: toMoneyFormat },
    { title: '결제방법', dataIndex: 'method' },
    { title: '카드번호', dataIndex: 'cardNumber' },
    {
      title: '영수증',
      render: (_, { receiptUrl }) =>
        receiptUrl ? (
          <a href={receiptUrl} rel="noopener noreferrer" target="_blank">
            열기
          </a>
        ) : (
          ''
        ),
    },
  ];

  return (
    <Table
      rowKey="orderId"
      dataSource={payment?.list || []}
      columns={columns}
      pagination={{
        showSizeChanger: false,
        total: payment?.total || 0,
        current: payment?.pageNum || 1,
        onChange: (current) => handleSetPage(current),
      }}
    />
  );
}

export default TabPaymentHistory;
