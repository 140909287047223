import { App as AntdApp, ConfigProvider as AntdConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import locale from 'antd/locale/ko_KR';

const withAntDesign = (Component) => {
  const WithAntDesign = (props) => {
    return (
      <AntdConfigProvider
        locale={locale}
        componentSize="middle"
        theme={{
          token: {
            colorPrimary: '#00AE95',
            fontFamily: 'Noto Sans KR, sans-serif',
          },
        }}
      >
        <StyleProvider hashPriority="high">
          <AntdApp>
            <Component {...props} />
          </AntdApp>
        </StyleProvider>
      </AntdConfigProvider>
    );
  };

  WithAntDesign.displayName = `withAntDesign(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithAntDesign;
};

export default withAntDesign;
