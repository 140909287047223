import { Navigate } from 'react-router-dom';

import { RuntimeErrorPage } from '@pages';

import publicRoutingConfig from './publicRoutingConfig';
import privateRouteConfig from './privateRouteConfig';
import App from '../App';

const routingConfig = () => {
  return [
    {
      path: '/',
      element: <App />,
      errorElement: <RuntimeErrorPage />,
      children: [...publicRoutingConfig, ...privateRouteConfig],
    },
    {
      index: true,
      // TODO: 임시용
      element: <Navigate to="/login" />,
    },
  ];
};

export default routingConfig;
