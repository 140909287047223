import { Form, Input } from 'antd';

function FormInput({ label, name, placeholder, ...rest }) {
  return (
    <Form.Item label={label} name={name}>
      <Input placeholder={placeholder} />
    </Form.Item>
  );
}

export default FormInput;
