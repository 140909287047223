import { useQuery } from '@tanstack/react-query';
import { logiApi } from '@utils/Api';

export const useGetDeliveryHistory = (params) =>
  useQuery(
    [`/external/v1/delivery-orders`, params],
    () => logiApi.get(`/external/v1/delivery-orders`, { params }),
    {
      enabled: !!params.startDate && !!params.endDate && !!params.type,
    },
  );

export const useGetDeliveryDetail = (params) =>
  useQuery(
    [`/external/v1/delivery-orders/${params.id}`],
    () => logiApi.get(`/external/v1/delivery-orders/${params.id}`),
    {
      enabled: !!params.id,
    },
  );

export const useGetDeliveryStatusHistory = (params) =>
  useQuery(
    [`/external/v1/delivery-orders/${params.id}/status-histories`],
    () =>
      logiApi.get(`/external/v1/delivery-orders/${params.id}/status-histories`),
    {
      enabled: !!params.id,
    },
  );
