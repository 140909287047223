/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Form, Radio, Table, Tooltip, message } from 'antd';

import { FormDatePicker } from '@components/form';
import { ModalDeliveryDetail, ModalDeliveryStatus } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { useGetDeliveryHistory } from '@api/delivery';
import { TagDeliveryStatus } from '@components/ui';
import { DATE_FORMAT, toDateFormat } from '@helper/formatHelper';
import { onCopyToClipboard } from '@helper/uiHelper';
import { useMount } from 'ahooks';

function TabDeliveryHistory() {
  const [payload, setPayload] = useState({ page: 1 });

  const [form] = Form.useForm();
  const { openModal } = useOverlayContext();

  const { data: delivery } = useGetDeliveryHistory(payload);

  const handleSubmit = (formValue) => {
    const {
      type,
      deliveryDate: [startDate, endDate],
    } = formValue;

    setPayload(() => ({
      type,
      page: 1,
      startDate: toDateFormat(startDate, DATE_FORMAT),
      endDate: toDateFormat(endDate, DATE_FORMAT),
    }));
  };

  const handleTracking = (invoiceNumber) => {
    return window.open(
      `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${invoiceNumber}`,
      '_blank',
    );
  };

  const handleCopy = (id) => {
    onCopyToClipboard(id);
    message.success('클립보드에 복사되었습니다.');
  };

  const handleOpenDeliveryDetail = ({ id, orderNo }) => {
    openModal(ModalDeliveryDetail, { id, orderNo });
  };

  const handleOpenDeliveryStatus = ({ id }) => {
    openModal(ModalDeliveryStatus, { id });
  };

  const handleSetPage = (page) => {
    setPayload((pre) => ({ ...pre, page }));
  };

  const columns = [
    {
      title: 'ID',
      width: 150,
      ellipsis: true,
      render: (row) => (
        <Tooltip
          title={
            <a style={{ color: 'white' }} onClick={handleCopy}>
              {row.id}
            </a>
          }
          placement="topLeft"
        >
          <a onClick={() => handleOpenDeliveryDetail(row)}>{row.id}</a>
        </Tooltip>
      ),
    },
    {
      title: '배송상태',
      dataIndex: 'status',
      render: (status) => <TagDeliveryStatus status={status} />,
      width: 120,
    },
    {
      title: '배송생성일자',
      dataIndex: 'createdDate',
      render: (date) => (
        <div>
          {toDateFormat(date)
            .split(' ')
            .map((dateStr) => (
              <div key={dateStr}>{dateStr}</div>
            ))}
        </div>
      ),
      width: 100,
    },
    { title: '수령인', dataIndex: 'recipient', width: 100 },
    {
      title: '주소',
      render: (row) => `${row.address} ${row.detailAddress} (${row.postalNo})`,
    },
    {
      title: '송장번호',
      render: (row) => (
        <a onClick={() => handleTracking(row.invoiceNumber)}>
          {row.invoiceNumber}
        </a>
      ),
      width: 120,
    },
    {
      title: '배송상태 이력',
      render: (row) => (
        <a onClick={() => handleOpenDeliveryStatus(row)}>보기</a>
      ),
      width: 100,
    },
  ];

  // 최초에 한번 그냥 조회
  useMount(() => {
    form.submit();
  });

  return (
    <Form form={form} onFinish={handleSubmit} layout="horizontal">
      <div className="flex flex-row justify-between mr-10">
        <FormDatePicker
          required
          label="배송주문일시"
          name="deliveryDate"
          onChange={() => setTimeout(() => form.submit())}
        />
        <Form.Item
          label="교재 타입"
          name="type"
          colon={false}
          initialValue="POD"
          rules={[{ required: true, message: '필수 입력 입니다.' }]}
        >
          <Radio.Group onChange={() => setTimeout(() => form.submit())}>
            <Radio value="POD">POD (주문제작 교재)</Radio>
            <Radio value="ISD">ISD (판매 교재)</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={delivery?.list || []}
        pagination={{
          showSizeChanger: false,
          total: delivery?.total || 0,
          current: delivery?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}

export default TabDeliveryHistory;
