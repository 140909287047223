import { isDevelopment, isLocal } from '@utils/systemPhaseUtils';

export const DATE_FORMAT = Object.freeze({
  FULL_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATETIME: 'YYYY-MM-DD HH:mm',
  TIME_FORMAT: 'HH:mm',
  TIME_FORMAT_SEC: 'HH:mm:ss',
  DAY_FORMAT: 'ddd',
});

export const ERROR_CODE = Object.freeze({
  '0000': { value: 0, label: '정상' },
  1002: {
    value: 1002,
    label: '등록된 데이터 정보가 없습니다.(DB 조회 실패)',
  },
  2002: { value: 2002, label: '네트워크 관련 오류' },
  2010: { value: 2010, label: '큐 로그인 실패' },
  2011: { value: 2011, label: '큐 로그아웃 실패' },
  2012: { value: 2012, label: '이미 큐에 로그인중' },
  2013: { value: 2013, label: '대상 큐가 존재하지 않음' },
  2500: {
    value: 2500,
    label: '이미 로그인 중인 내선으로 로그인 명령 차단됨',
  },
  2501: { value: 2501, label: 'CTI 로그인 상태가 아닙니다.' },
  2502: { value: 2502, label: '이미 로그인 중인 내선으로 소켓 연결 종료됨' },
  2504: { value: 2504, label: '단말기 연결 실패' },
  2600: { value: 2600, label: '돌려주기 실패 (착신 가능채널 존재하지 않음)' },
  2601: { value: 2601, label: '내선번호가 존재하지 않음' },
  3001: { value: 3001, label: '발신 실패' },
  3002: { value: 3002, label: '끊기 실패' },
  3010: { value: 3010, label: '전화 받기 실패 (수신채널 없음)' },
  3020: { value: 3020, label: '음소거 실패 (채널이 존재하지 않음)' },
  3030: {
    value: 3030,
    label: '돌려주기 실패 (채널이 존재하지 않거나 대상 내선이 없음)',
  },
  4001: { value: 4001, label: '통화중인 채널을 찾을 수 없음' },
  4002: { value: 4002, label: '채널이 닫혀 있음' },
});

export const PAUSE_REASON = {
  10: { value: 'AFTER', label: '후처리' },
  11: { value: 'LEAVE', label: '자리비움' },
  12: { value: 'AT_TABLE', label: '식사중' },
  13: { value: 'PAUSE', label: '수신거부' },
};

export const CALL_EVENT = Object.freeze({
  /**
   * RINGING : 링울림
   * ANSWER: 통화연결
   * HANGUP: 통화종료
   * */
  RINGING: 'RINGING',
  ANSWER: 'ANSWER',
  HANGUP: 'HANGUP',
});

export const DEVICE_STATUS = Object.freeze({
  /**
   * RINGING : 링울림
   * INUSE : 통화중(링울림, 통화연결)
   * NOT_INUSE : 미통화
   * UNAVAILABLE : 단말기 연결 실패
   * */
  RINGING: 'RINGING',
  INUSE: 'INUSE',
  NOT_INUSE: 'NOT_INUSE',
  UNAVAILABLE: 'UNAVAILABLE',
});
export const QUEUE_REASON = Object.freeze({
  AFTER: { id: 'AFTER', value: '10', label: '후처리' },
  LEAVE: { id: 'LEAVE', value: '11', label: '자리비움' },
  AT_TABLE: { id: 'AT_TABLE', value: '12', label: '식사중' },
  PAUSE: { id: 'PAUSE', value: '13', label: '수신거부' },
});
export const AGENT_SOCKET_STATUS = Object.freeze({
  /**
   * queueConnected : 큐 로그인 한 상태
   * readyToQueueConnect : AGENT_LOGIN이 되어 큐 로그인할 준비가 되어있는 상태
   * notReadyToQueueConnect : 로그인이 안되어 있음 / 큐 로그인 안되어 있음
   * pause : 수신 거절
   * activate : 수신 대기
   * leave : 이석(자리비움)
   * after : 문서(후처리)
   * */
  QUEUE_CONNECTED: 'queueConnected',
  READY_TO_QUEUE_CONNECT: 'readyToQueueConnect',
  NOT_READY_TO_QUEUE_CONNECT: 'notReadyToQueueConnect',
  ACTIVATE: 'activate',
  PAUSE: 'pause',
  LEAVE: 'leave',
  AFTER: 'after',
  AT_TABLE: 'at_table',
});

export const STATUS_LABEL = Object.freeze([
  {
    label: '로그인',
    className: 'live-login',
  },
  {
    label: '로그아웃',
    className: 'live-not-login',
  },
  {
    label: '통화대기',
    className: 'live-ready',
  },
  {
    label: '통화중',
    className: 'live-calling',
  },
  {
    label: '수신거절',
    className: 'live-paused',
  },
  {
    label: '문서',
    className: 'live-after',
  },
  {
    label: '이석',
    className: 'live-leave',
  },
  {
    label: '서버와 연결끊김',
    className: 'live-disconnect',
  },
]);
export const HELP_DESK_NUMBER = Object.freeze({
  WINK: '15221244',
  WINK_BOOKS: '18551244',
  CATS_HELP_DESK_NUMBER: '18551244_1',
});
export const ENV_VAR = Object.freeze({
  REMOTE_IP: process.env.REACT_APP_HELP_DESK_REMOTE_IP || '110.8.123.58',
  OFFICE_IP: process.env.REACT_APP_HELP_DESK_OFFICE_IP || '158.163.103.30',
  ACCOUNT_KEY:
    process.env.HANIL_ACCOUNT_KEY ||
    '6e971f7174eadafbbed5ac16e12170425b0bca5cd024c3bc2e05e7cae3918979164251078b2d57149daa33fc46cf16e06bb3990b13b4f048f55f9202808f9405',
});

export const EMIT_CMD = Object.freeze({
  AGENT_LOGIN: 'AGENT_LOGIN',
  AGENT_LOGOUT: 'AGENT_LOGOUT',
  OUTCALL: 'OUTCALL', // 전화 걸기
  ANSWER: 'ANSWER', // 전화 받기
  PICKUP: 'PICKUP', // 당겨 받기
  TRANSFER: 'TRANSFER', // 돌려주기
  UNTRANSFER: 'UNTRANSFER', // 돌려주기 취소
  HANGUP: 'HANGUP', // 전화 끊기
  IVR_ROUTING: 'IVR_ROUTING', // IVR 시나리오 송출 (통화종료됨)
  QUEUE_LOGIN: 'QUEUE_LOGIN', // 큐 로그인 (대표번호 수신이 필요한 경우)
  QUEUE_LOGOUT: 'QUEUE_LOGOUT', // 큐 로그아웃
  QUEUE_READY: 'QUEUE_READY', // 접속된 큐에서 전화수신대기 (이후 전화 울림)
  // TODO: UNUSED
  // MUTE: 'MUTE', // 음소거
  // UNMUTE: 'UNMUTE', // 음소거 해제
  // HOLD: 'HOLD', // 보류
  // UNHOLD: 'UNHOLD', // 보류 해제
});

export const CMD_TYPE = Object.freeze({
  RESPONSE_AGENT_LOGIN: 'AGENT_LOGIN',
  RESPONSE_AGENT_LOGOUT: 'AGENT_LOGOUT',
  RESPONSE_OUTCALL: 'OUTCALL',
  RESPONSE_ANSWER: 'ANSWER',
  RESPONSE_PICKUP: 'PICKUP',
  RESPONSE_TRANSFER: 'TRANSFER',
  RESPONSE_UNTRANSFER: 'UNTRANSFER',
  RESPONSE_HANGUP: 'HANGUP',
  RESPONSE_QUEUE_LOGIN: 'QUEUE_LOGIN',
  RESPONSE_QUEUE_READY: 'QUEUE_READY',
  // TODO: UNUSED
  // RESPONSE_QUEUE_LOGOUT: 'QUEUE_LOGOUT',
  // RESPONSE_MUTE: 'MUTE',
  // RESPONSE_UNMUTE: 'UNMUTE',
  // RESPONSE_HOLD: 'HOLD',
  // RESPONSE_UNHOLD: 'UNHOLD',

  EVENT_PEER_STATUS: 'EVT_PEER_STATUS',
  EVENT_RING: 'EVT_RING',
  EVENT_ANSWER: 'EVT_ANSWER',
  EVENT_HANGUP: 'EVT_HANGUP',
  EVENT_QUEUE_LOGIN: 'EVT_QUEUE_LOGIN',
  EVENT_QUEUE_LOGOUT: 'EVT_QUEUE_LOGOUT',
  EVENT_QUEUE_READY: 'EVT_QUEUE_READY',

  // monitor
  MONITOR_EVENT_QUEUE: 'EVENT_QUEUE',
  MONITOR_EVENT_AGENT: 'EVENT_AGENT',
  MONITOR_EVENT_MEMBER: 'EVENT_MEMBER',
  MONITOR_EVENT_CALLER: 'EVENT_CALLER',

  MONITOR_SAVE_MYCALL: 'SAVE_MYCALL',
});

const IVR_SCENARIO = {
  step: 1,
  label: '문의',
  branch: {
    1: {
      step: 2,
      label: '선생님',
      branch: {
        1: {
          step: 3,
          label: '구독회원',
        },
        2: {
          step: 3,
          label: '신청',
        },
      },
    },
    2: {
      step: 2,
      label: '학생',
      branch: {
        1: {
          step: 3,
          label: '구독회원',
          branch: {
            // 1: {},
            // 2: {},
          },
        },
        2: {
          step: 3,
          label: '체험 중 회원',
        },
        3: {
          step: 3,
          label: '무료체험 신청',
        },
      },
    },
  },
};

export const CATS_QUEUE =
  isLocal() || isDevelopment() ? '15331244_1' : '16601244_1';
