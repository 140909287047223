import dayjs from 'dayjs';
import { assignIn } from 'lodash';

import { isDevelopment, isLocal, isProduction } from '@utils/systemPhaseUtils';

import { CATS_QUEUE } from '../data/constants';

const now = () => dayjs().format('HH:mm:ss');

export const onMessageEventQueue = (data) => {
  let callback;
  switch (data.queue) {
    case '16601244_1':
      if (isProduction()) {
        callback = (state) => {
          state.catsQueue = data;
        };
      }
      break;
    case '15331244_1':
      if (isDevelopment() || isLocal()) {
        callback = (state) => {
          state.catsQueue = data;
        };
      }
      break;
    default:
      break;
  }
  if (!callback) return null;
  return [callback, false, '@@MONITOR :: [EVENT_QUEUE]'];
};
export const onMessageEventAgent = (data, agentNumber) => {
  return [
    (state) => {
      const catsAgentNumber = isLocal() ? '9090' : agentNumber;
      if (data.agent === catsAgentNumber) {
        state.catsAgent = data;
      }
      state.agents[data.agent] = data;
    },
    false,
    '@@MONITOR :: [EVENT_AGENT]',
  ];
};
export const onMessageEventMember = (data) => {
  return [
    (state) => {
      state.agents[data.agent] = assignIn(state.agents[data.agent], {
        eventMember: data,
        readyTime: data.status === '2' && data.paused === '0' ? now() : '',
        loginTime:
          data?.status === '1'
            ? now()
            : state.agents[data.agent]?.loginTime ?? '',
      });
    },
    false,
    '@@MONITOR :: [EVENT_MEMBER]',
  ];
};

export const onMessageEventCaller = (data) => {
  let callback;
  const timeStampedData = {
    ...data,
    timestamp: now(),
  };
  switch (data.join) {
    case 0:
      callback = (state) => {
        state.abandonedCalls.push(timeStampedData);
      };
      break;
    case 1:
      callback = (state) => {
        if (data.queue === CATS_QUEUE) {
          state.catsJoinedCalls.push(timeStampedData);
        }
        state.joinedCalls.push(timeStampedData);
      };
      break;
    default:
      break;
  }
  if (!callback) return null;
  return [callback, false, '@@MONITOR :: [EVENT_CALLER]'];
};
