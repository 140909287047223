import { createContext, useContext, useState, useCallback } from 'react';

import { Spin } from 'antd';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

const LoadingContext = createContext();

function LoadingProvider({ children }) {
  const [shows, setShows] = useState(false);
  const [usesLoading, setUsesLoading] = useState(true);

  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const enable = useCallback(() => setUsesLoading(true), []);

  const disable = useCallback(() => setUsesLoading(false), []);

  const show = useCallback(() => setShows(true), []);

  const hide = useCallback(() => setShows(false), []);

  return (
    <LoadingContext.Provider value={{ enable, disable, show, hide }}>
      {usesLoading && (
        <Spin
          style={{ maxHeight: '100vh' }}
          spinning={shows || isFetching || isMutating}
          size="large"
          tip="로딩중..."
        >
          {children}
        </Spin>
      )}
    </LoadingContext.Provider>
  );
}

function useLoadingContext() {
  return useContext(LoadingContext);
}

export { LoadingProvider, useLoadingContext };
