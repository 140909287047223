import { useMemo } from 'react';
import { FloatButton } from 'antd';

import { useMonitorSocketStore } from '../store/useMonitorSocketStore';

const POSITION_RIGHT = 35;
const BUTTON_GAP = 85;

function CallCategory({ label, field, index }) {
  const catsQueue = useMonitorSocketStore((state) => state.catsQueue);
  const count = useMemo(() => {
    // 콜처리율 인 경우에만 계산
    if (field === 'ratio') {
      const totalSucCnt = catsQueue?.suc_call || 0;
      const totalCnt = (catsQueue?.fail_call || 0) + totalSucCnt;

      if (totalCnt === 0) return '-';
      return `${Number((totalSucCnt / totalCnt) * 100).toFixed(0)}%`;
    }
    // 나머지는 그냥
    return catsQueue?.[field] ?? '-';
  }, [catsQueue, field]);

  return (
    <FloatButton
      shape="square"
      description={label}
      badge={{ count }}
      className="w-[70px] h-[40px] top-[70px] z-[2000]"
      style={{
        right: POSITION_RIGHT + BUTTON_GAP * index,
      }}
    />
  );
}

export default CallCategory;
