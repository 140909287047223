import { useEffect } from 'react';
import { Button, Cascader, Col, Drawer, Form, Input, Radio, Row } from 'antd';
import { isEmpty } from 'lodash';

import { toPhoneNumberFormat } from '@helper/formatHelper';
import { usePostConsultation } from '@api';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import useConsultationStore from '@store/useConsultationStore';

function DrawerConsultation() {
  const [form] = Form.useForm();

  const reset = () => {
    form.resetFields(['category', 'content']);
  };

  const { currentMember: member } = useConsultationStore((state) => state);
  const requestConsultation = usePostConsultation(reset);

  const { [CODE_KEY.CONSULTATION_CATEGORY_TREE]: consultCategoryTree } =
    useCodeStore((state) => state.code);

  const handleSubmit = (formValue) => {
    const { errors } = form.getFieldsError();

    if (isEmpty(errors) && !isEmpty(formValue)) {
      requestConsultation(
        {
          ...formValue,
          ...(member?.memberId && { customerId: member.memberId }),
          category: formValue.category.pop(),
          customerPhone: member?.mobile || '',
        },
        { onSuccess: () => reset() },
      );
    }
  };

  useEffect(() => {
    form.setFieldsValue({ customerName: member?.memberName || '' });
    form.setFieldsValue({ content: '' });
  }, [form, member?.memberName]);

  return (
    <Drawer
      placement="right"
      open
      mask={false}
      width={530}
      headerStyle={{ display: 'none' }}
      bodyStyle={{
        padding: 0,
        marginTop: '200px',
        marginBottom: '20px',
        marginRight: '20px',
        borderRadius: '5px 0 0 0 ',
        background: 'white',
      }}
      contentWrapperStyle={{ boxShadow: 'none' }}
      style={{ background: 'none' }}
      className="register-consultation-detail-drawer-wrapper"
    >
      <div className="flex flex-row h-full border-2 border-primary">
        <div className="m-7 w-full">
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[40, 0]}>
              <Col span={12}>
                <Form.Item label="이름" name="customerName" colon={false}>
                  <Input
                    maxLength={20}
                    disabled={!isEmpty(member?.memberName)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="연락처" colon={false}>
                  {toPhoneNumberFormat(member?.mobile || '')}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item name="callType" initialValue="INBOUND">
                <Radio.Group>
                  <Radio value="INBOUND">수신</Radio>
                  <Radio value="OUTBOUND">발신</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                colon={false}
                name="category"
                label="카테고리"
                initialValue=""
                rules={[{ required: true, message: '필수 입력 입니다.' }]}
              >
                <Cascader
                  options={consultCategoryTree}
                  placeholder="카테고리 선택"
                  allowClear
                />
              </Form.Item>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="메모"
                  name="content"
                  rules={[{ required: true, message: '필수 입력 입니다.' }]}
                  colon={false}
                >
                  <Input.TextArea rows="8" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Button htmlType="submit" type="primary">
                저장
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerConsultation;
