import { useState } from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import privateRouteConfig from '@config/privateRouteConfig';

function Lnb() {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  const lnbItems = privateRouteConfig
    .filter((item) => item.path.split('/').length === 1)
    .map((item) => item.option);

  const handleNavigate = ({ key }) => {
    navigate(`/${key}`);
  };

  return (
    <>
      <Layout.Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          theme="light"
          items={lnbItems}
          defaultSelectedKeys={[lnbItems[0].key]}
          onClick={handleNavigate}
        />
      </Layout.Sider>
    </>
  );
}

export default Lnb;
