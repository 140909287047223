const DELIVERY_STATUS_TAG_COLORS = Object.freeze({
  POD01001: 'gray',
  POD01002: 'processing',
  POD01003: 'cyan',
  POD01004: 'success',
  POD01999: 'error',
  ISD01001: 'gray',
  ISD01002: 'processing',
  ISD01003: 'cyan',
  ISD01004: 'cyan',
  ISD01005: 'success',
  ISD01006: 'processing',
  ISD01999: 'error',
});

export { DELIVERY_STATUS_TAG_COLORS };
