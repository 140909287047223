import { useEffect } from 'react';
import { useUnmount } from 'ahooks';

import useConsultationDetail from '@hooks/consultation/useConsultationDetail';
import useConsultationStore from '@store/useConsultationStore';

import DrawerConsultation from './components/DrawerConsultation';
import DescriptionMember from './components/DescriptionMember';
import DescriptionNonMember from './components/DescriptionNonMember';

function ConsultationPageDeail() {
  const { members, currentMember, setCurrentMember, setMembers } =
    useConsultationStore((state) => state);
  const { handleCheckMembers } = useConsultationDetail();

  // 조회한 회원이 있는 경우 (1명 이상)
  useEffect(() => {
    if (Number(members?.total) > 0) {
      handleCheckMembers(members);
    }
  }, [handleCheckMembers, members]);

  // 페이지 이동 시 store 삭제
  useUnmount(() => {
    setMembers({});
    setCurrentMember({});
  });

  return (
    <>
      <DrawerConsultation />
      <div className="flex flex-row w-full min-w-[1028px]">
        <div className="w-[70%]">
          {currentMember?.memberId ? (
            <DescriptionMember />
          ) : (
            <DescriptionNonMember />
          )}
        </div>
      </div>
    </>
  );
}

export default ConsultationPageDeail;
