import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from '@utils/Api';
import { useOverlayContext } from '@context/OverlayContext';
import storage from '@utils/Storage';

export const useToken = () => {
  const { openAlert } = useOverlayContext();

  const onError = () => {
    openAlert('아이디 혹은 비밀번호가 틀렸습니다');
  };

  const mutation = useMutation((data) =>
    api.post('/external/p2p/auth/send-talk', data),
  );

  const requestToken = ({ userId, userPw }, { onSuccess }) => {
    mutation.mutate(
      { userId, userPw },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError,
      },
    );
  };

  return requestToken;
};

export const useLogin = () => {
  const { openAlert } = useOverlayContext();
  const queryClient = useQueryClient();

  const onError = () => {
    openAlert('입력하신 정보가 잘못되었습니다.');
  };

  const mutation = useMutation((data) =>
    api.post('/external/admin/loginProcess', data),
  );

  const requestLogin = ({ userId, userPw, authKey }, { onSuccess }) => {
    mutation.mutate(
      { userId, userPw, authKey },
      {
        onSuccess: (result) => {
          const { accessToken, refreshToken } = result;
          storage.setToken(accessToken, refreshToken);

          queryClient.invalidateQueries('/external/v1/agent-numbers');

          onSuccess?.(result);
        },
        onError,
      },
    );
  };

  return requestLogin;
};

/**
 * 배송 상태 코드 조회
 */
export const useGetAgentNumber = () =>
  useQuery(
    ['/external/v1/agent-numbers'],
    () => api.get('/external/v1/agent-numbers'),
    { enabled: false },
  );

export const useGetMembers = (params, enabled = true) =>
  useQuery(
    ['/v1/member', params],
    () => api.get('/v1/member', { params: { ...params, pageSize: 100 } }),
    {
      enabled: enabled && !!params.searchKey && !!params.searchValue,
    },
  );

export const useGetMemberDetail = ({ type, memberId }) =>
  useQuery(
    [`/v1/member/${type}/${memberId}`],
    () => api.get(`/v1/member/${type}/${memberId}`),
    {
      enabled: !!type && !!memberId,
    },
  );
