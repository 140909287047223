import { Table } from 'antd';

import { useGetConsultation } from '@api/consultation';
import { CALL_TYPE } from '@constants/consultation';
import { toDateFormat, toPhoneNumberFormat } from '@helper/formatHelper';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import useConsultationStore from '@store/useConsultationStore';
import { useState } from 'react';

function TabConsultationHistory() {
  const [page, setPage] = useState(1);

  const currentMember = useConsultationStore((state) => state.currentMember);

  const { data: consultation } = useGetConsultation({
    page,
    customerId: currentMember?.memberId,
  });

  const { [CODE_KEY.CONSULTATION_CATEGORY]: consultCategories } = useCodeStore(
    (state) => state.code,
  );

  const handleSetPage = (no) => {
    setPage(no);
  };

  const columns = [
    {
      title: '카테고리',
      dataIndex: 'category',
      render: (category) => consultCategories?.[category] || '-',
    },
    { title: '회원명', dataIndex: 'customerName', width: 80 },
    {
      title: '전화번호',
      dataIndex: 'customerPhone',
      render: toPhoneNumberFormat,
    },
    { title: '내용', dataIndex: 'content', width: 400 },
    {
      title: '날짜',
      dataIndex: 'createdDate',
      render: (createdDate) => toDateFormat(createdDate),
    },
    { title: '상담사', dataIndex: 'agentName' },
    {
      title: '수신/발신',
      dataIndex: 'callType',
      render: (callType) => CALL_TYPE?.[callType],
    },
  ];

  return (
    <Table
      rowKey="createdDate"
      dataSource={consultation?.list || []}
      columns={columns}
      pagination={{
        showSizeChanger: false,
        total: consultation?.total || 0,
        current: consultation?.pageNum || 1,
        onChange: (current) => handleSetPage(current),
      }}
    />
  );
}

export default TabConsultationHistory;
