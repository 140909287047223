const CALL_TYPE = Object.freeze({
  INBOUND: '수신',
  OUTBOUND: '발신',
});

const MEMBER_TYPE_CD = Object.freeze({
  TEACHER: 'CD10001',
  STUDENT: 'CD10002',
  PARENTS: 'CD10003',
});

export { CALL_TYPE, MEMBER_TYPE_CD };
