import { Modal, Button, Descriptions, Table } from 'antd';

import { useGetDeliveryDetail } from '@api/delivery';
import { useOverlayContext } from '@context/OverlayContext';

import ModalContainer from './base/ModalContainer';

function ModalDeliveryDetail({ id, orderNo }) {
  const { closeModal } = useOverlayContext();
  const { data: delivery } = useGetDeliveryDetail({ id });

  const handleClose = () => {
    closeModal();
  };
  return (
    <ModalContainer>
      <Modal
        open
        title="배송주문 상세정보"
        closable={false}
        footer={<Button onClick={() => handleClose()}>닫기</Button>}
        width="60%"
      >
        <Descriptions bordered className="my-5">
          <Descriptions.Item
            label="배송주문번호(ID)"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            <div className="w-fit">{id}</div>
          </Descriptions.Item>
          <Descriptions.Item
            label="주문번호(ID)"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            {orderNo}
          </Descriptions.Item>
        </Descriptions>
        <Table
          rowKey="productId"
          total={delivery?.deliveryOrderLines.length || 0}
          dataSource={delivery?.deliveryOrderLines || []}
          columns={[
            { title: '상품 ID', dataIndex: 'productId', width: 200 },
            { title: '상품명', dataIndex: 'productName' },
            { title: '개수', dataIndex: 'count', width: 80 },
          ]}
          pagination={false}
        />
      </Modal>
    </ModalContainer>
  );
}

export default ModalDeliveryDetail;
