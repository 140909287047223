import { Tag } from 'antd';

import { DELIVERY_STATUS_TAG_COLORS } from '@constants/delivery';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';

function TagDeliveryStatus({ status }) {
  const { [CODE_KEY.DELIVERY_STATUS]: deliveryStatus } = useCodeStore(
    (state) => state.code,
  );

  return (
    <Tag color={DELIVERY_STATUS_TAG_COLORS[status]}>
      {deliveryStatus?.[status]?.label ?? ''}
    </Tag>
  );
}

export default TagDeliveryStatus;
