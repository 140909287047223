import { api, casApi } from '@utils/Api';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useOverlayContext } from '@context/OverlayContext';

/**
 * 상담 목록 조회
 */
export const useGetConsultation = (params) =>
  useQuery(
    ['/external/v1/customer-consults', params],
    () => api.get('/external/v1/customer-consults', { params }),
    {
      enabled:
        !!params.customerPhone ||
        !!params.customerId ||
        (!!params.startDate && !!params.endDate),
    },
  );

export const useGetSubscribeHistory = (params) =>
  useQuery(
    [`/v1/member/student/${params.memberId}/subscribe-list`],
    () => api.get(`/v1/member/student/${params.memberId}/subscribe-list`),
    {
      enabled: !!params.memberId,
    },
  );

export const useGetPaymentHistory = ({ memberId, page }) =>
  useQuery(
    [`/v1/member/${memberId}/order-payments`, { page }],
    () =>
      api.get(`/v1/member/${memberId}/order-payments`, { params: { page } }),
    {
      enabled: !!memberId,
    },
  );

export const usePostConsultation = (reset) => {
  const queryClient = useQueryClient();
  const { openAlert, openSuccess } = useOverlayContext();

  const onSuccess = () => {
    queryClient.invalidateQueries('/external/v1/customer-consults');
    openSuccess('저장 되었습니다.');
    reset?.();
  };

  const onError = () => {
    openAlert('저장이 실패했습니다.');
  };

  const mutation = useMutation(
    (consultation) =>
      api.post('/external/v1/customer-consults', {
        ...consultation,
      }),
    { onSuccess, onError },
  );

  const requestConsultation = (consultation) => {
    mutation.mutate(consultation);
  };

  return requestConsultation;
};
