import { useGetConsultationCategory, useGetDeliveryStatus } from '@api/code';
import { create } from 'zustand';
import produce from 'immer';

export const CODE_KEY = {
  DELIVERY_STATUS: 'deliveryStatus',
  POD_DELIVERY_STATUS: 'podDeliveryStatus',
  ISD_DELIVERY_STATUS: 'isdDeliveryStatus',
  CONSULTATION_CATEGORY: 'consultationCategory',
  CONSULTATION_CATEGORY_TREE: 'consultCategoryTree',
};

const initialValue = {};

const useCodeStore = create((set) => ({
  code: initialValue,
  clear: () => set(initialValue),
  setCode: (code) =>
    set(
      produce((state) => {
        Object.keys(code).forEach((key) => {
          state.code[key] = code[key];
        });
      }),
    ),
}));

const makeLeafConsultCategoriesDictionary = (consultCategoryTree) => {
  const psObj = {};
  const ps = (obj, accumulatedLabelArr) => {
    const { label, value, children } = obj;
    if (children?.length) {
      children.forEach((child) => {
        ps(child, [...accumulatedLabelArr, label]);
      });
      return;
    }
    psObj[value] = [...accumulatedLabelArr, label].join(' \n> ');
  };
  consultCategoryTree.forEach((consultCategoryObj) => {
    ps(consultCategoryObj, []);
  });
  return psObj;
};

export const useCodeStoreActions = () => {
  const setCode = useCodeStore((state) => state.setCode);

  const { refetch: refetchDeliveryStatus } = useGetDeliveryStatus();
  const { refetch: refetchConsultationCategory } = useGetConsultationCategory();

  const fetchDeliveryStatusCode = () => {
    (async () => {
      try {
        const { data } = await refetchDeliveryStatus();
        const deliveryStatus = JSON.parse(data);

        const podDeliveryStatus = {};
        const isdDeliveryStatus = {};

        Object.keys(deliveryStatus).forEach((key) => {
          if (key.includes('POD')) {
            podDeliveryStatus[key] = {
              label: deliveryStatus[key],
              value: key,
            };
          }
          if (key.includes('ISD')) {
            isdDeliveryStatus[key] = {
              label: deliveryStatus[key],
              value: key,
            };
          }
        });

        setCode({
          [CODE_KEY.DELIVERY_STATUS]: {
            ...podDeliveryStatus,
            ...isdDeliveryStatus,
          },
          [CODE_KEY.POD_DELIVERY_STATUS]: podDeliveryStatus,
          [CODE_KEY.ISD_DELIVERY_STATUS]: isdDeliveryStatus,
        });
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  const fetchConsultationCategory = () => {
    (async () => {
      try {
        const { data } = await refetchConsultationCategory();
        const consultationCategory = JSON.parse(
          JSON.stringify(data)
            .replaceAll(/"name"(|\s+):/g, '"label":')
            .replaceAll(/"code"(|\s+):/g, '"value":')
            .replaceAll(/"members"(|\s+):/g, '"children":'),
        );

        setCode({
          [CODE_KEY.CONSULTATION_CATEGORY_TREE]: consultationCategory,
          [CODE_KEY.CONSULTATION_CATEGORY]:
            makeLeafConsultCategoriesDictionary(consultationCategory),
        });
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  return {
    fetchDeliveryStatusCode,
    fetchConsultationCategory,
  };
};

export default useCodeStore;
