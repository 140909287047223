import dayjs from 'dayjs';

const timeFormat = (time) => {
  const m = Math.floor(time / 60).toString();
  let s = (time % 60).toString();
  if (s.length === 1) s = `0${s}`;
  return `${m}:${s}`;
};

const format = (date, formatString = 'YYYY-MM-DD HH:mm') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const dateFormat = (date, formatString = 'YYYY-MM-DD') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const hourFormat = (date, formatString = 'HH') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const minuteFormat = (date, formatString = 'mm') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const dateReplaceFormat = (date, formatString = 'YYYYMMDD') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const dateMonthFormat = (date, formatString = 'YYYYMM') => {
  return dayjs(date).isValid() ? dayjs(date).format(formatString) : '-';
};

const weekAgo = () => {
  return dayjs().subtract(7, 'day');
};

const monthAgo = (monthly) => {
  return dayjs().subtract(monthly, 'month');
};

const monthDays = (year, month) => {
  const days = [];
  // 해당 월의 첫 번째 날짜를 가져옵니다.
  const firstDayOfMonth = dayjs(`${year}-${month}-01`);

  // 해당 월의 마지막 날짜를 가져옵니다.
  const lastDayOfMonth = firstDayOfMonth.endOf('month');

  // 해당 월의 모든 날짜를 순회하며 일(요일) 형태로 출력합니다.
  let currentDate = firstDayOfMonth;
  while (currentDate.isSameOrBefore(lastDayOfMonth)) {
    days.push(currentDate.format('YYYY-MM-DD (ddd)'));
    currentDate = currentDate.add(1, 'day');
  }
  return days;
};

const getHourlyIntervals = (start, end) => {
  const startTime = dayjs().hour(start).minute(0).second(0);
  const endTime = dayjs().hour(end).minute(0).second(0); // 21:00을 제외합니다.
  const intervals = [];

  let currentTime = startTime;

  while (currentTime.isSameOrBefore(endTime)) {
    if (currentTime.format('HH:mm') !== '21:00') {
      intervals.push(currentTime.format('HH'));
    }
    currentTime = currentTime.add(1, 'hour');
  }

  return intervals;
};

const getCalendarMatrix = (date) => {
  const year = date.format('YYYY');
  const month = date.format('MM');
  const monthStart = dayjs(`${year}-${month}-01`);
  const firstDayOfWeek = monthStart.startOf('week');
  const daysInMonth = monthStart.daysInMonth();
  const monthEnd = dayjs(`${year}-${month}-${daysInMonth}`);
  const lastDayOfWeek = monthEnd.endOf('week');

  let weekDate = firstDayOfWeek;
  const calendar = [];

  while (weekDate.isBefore(lastDayOfWeek) || weekDate.isSame(lastDayOfWeek)) {
    const week = [];
    for (let i = 0; i < 7; i += 1) {
      week.push(
        weekDate.month() === monthStart.month()
          ? { day: weekDate.date(), week: weekDate.get('day') }
          : '',
      );
      weekDate = weekDate.add(1, 'day');
    }
    calendar.push(week);
  }

  return calendar;
};

const isWithinRange = (start, end, specific) => {
  return (
    dayjs(specific).isSameOrAfter(dayjs(start)) &&
    dayjs(specific).isBefore(dayjs(end))
  );
};

export {
  format,
  timeFormat,
  dateFormat,
  hourFormat,
  minuteFormat,
  dateReplaceFormat,
  dateMonthFormat,
  weekAgo,
  monthAgo,
  monthDays,
  isWithinRange,
  getHourlyIntervals,
  getCalendarMatrix,
};
