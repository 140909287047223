import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useGetMemberDetail } from '@api';
import { useOverlayContext } from '@context/OverlayContext';
import { MEMBER_TYPE_CD } from '@constants/consultation';
import useConsultationStore from '@store/useConsultationStore';
import { ModalMemberSelector } from '@components/overlay';

function useConsultationDetail() {
  const { openModal } = useOverlayContext();
  const setCurrentMember = useConsultationStore(
    (state) => state.setCurrentMember,
  );

  const [payload, setPayload] = useState({
    memberId: '',
    type: '',
    memberTypeNm: '',
  });

  const { data: memberDetail } = useGetMemberDetail(payload);

  const handleGetMemberDetail = useCallback(
    ({ memberId, memberType, memberTypeNm }) => {
      const type = Object.keys(MEMBER_TYPE_CD)
        .find((key) => MEMBER_TYPE_CD[key] === memberType)
        .toLowerCase();

      setPayload({ memberId, type, memberTypeNm });
    },
    [],
  );

  const handleCheckMembers = useCallback(
    (result) => {
      const total = result?.list?.length || result?.total;
      // 조회된 회원이 여려명인 경우
      if (total > 1) {
        openModal(ModalMemberSelector, {
          members: result,
          onSuccess: (member) => handleGetMemberDetail(member),
        });
      }

      // 1명인 경우
      if (total === 1) {
        const [member] = result.list;
        handleGetMemberDetail({
          memberId: member.memberId,
          memberType: member.memberTypes?.[0].memberTypeCd,
        });
      }
    },
    [handleGetMemberDetail, openModal],
  );

  useEffect(() => {
    if (!isEmpty(memberDetail)) {
      setCurrentMember({
        ...memberDetail,
        memberTypeNm: payload?.memberTypeNm,
      });
    }
  }, [memberDetail, payload, setCurrentMember]);

  return {
    setCurrentMember,
    handleCheckMembers,
    handleGetMemberDetail,
  };
}

export default useConsultationDetail;
