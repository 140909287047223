import { createContext, useContext } from 'react';
import { useMount } from 'ahooks';
import * as UAParser from 'ua-parser-js';

const AppContext = createContext({});

const uaParser = new UAParser.UAParser();

function AppProvider({ children }) {
  uaParser.setUA(window.navigator.userAgent);

  useMount(() => {
    window.addEventListener('beforeunload', () => {
      window.history.replaceState({}, document.title);
    });
  });

  return (
    <AppContext.Provider
      value={{
        os: uaParser.getOS(),
        browser: uaParser.getBrowser(),
        cpu: uaParser.getCPU(),
        device: uaParser.getDevice(),
        engine: uaParser.getEngine(),
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  return useContext(AppContext);
}

export { AppProvider, useAppContext };
