import { useCallback } from 'react';
import { create } from 'zustand';
import { useGetAgentNumber } from '@api/account';
import { callSocketActions } from '@feature/cti/actions/callSocketActions';
import dayjs from 'dayjs';
import { useMonitorSocketStore } from '@feature/cti/store/useMonitorSocketStore';

const initialValue = {
  agentNumber: '',
  id: '',
};

const useAccountStore = create((set) => ({
  ...initialValue,
  clear: () => set(initialValue),
  setAccount: ({ agentNumber, email }) =>
    set((state) => ({ ...state, agentNumber, email })),
}));

export const useAccountActions = () => {
  const setAccount = useAccountStore((state) => state.setAccount);

  const { refetch: refetchAccount } = useGetAgentNumber();

  const fetchAccount = useCallback(() => {
    (async () => {
      const { data } = await refetchAccount();

      if (data?.number) callSocketActions.agentLogin(data?.number);
      if (
        dayjs().isAfter(dayjs(useMonitorSocketStore.getState().baseDate), 'day')
      ) {
        useMonitorSocketStore.persist.clearStorage();
      }
      setAccount({
        agentNumber: data?.number,
        agentId: data?.agentId,
      });
    })();
  }, [refetchAccount, setAccount]);

  return {
    fetchAccount,
  };
};

export default useAccountStore;
