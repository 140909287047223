// Ref: {@link https://github.com/pmndrs/zustand#middleware}
// Ref: {@link https://docs.pmnd.rs/zustand/recipes/recipes#middleware}

import { isProduction } from '@utils/systemPhaseUtils';
import produce from 'immer';
import { omit } from 'lodash';

export const logger = (f) => (set, get, api) =>
  f(
    (...args) => {
      if (isProduction()) {
        set(...args);
        return;
      }
      console.groupCollapsed(
        `  %cACTION:: %c${args[2] ?? ''}`,
        'color: orange',
        'color: gold',
      );
      console.log('  %cPREV STATE', 'color: pink', omit(get(), 'actions'));
      set(...args);
      console.log('  %cNEXT STATE', 'color: skyblue', omit(get(), 'actions'));
      console.groupEnd();
    },
    get,
    api,
  );

export const immer = (config) => (set, get, api) =>
  config((fn) => set(produce(fn)), get, api);
