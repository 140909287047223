import { EMIT_CMD } from '../data/constants';
import { callerSocket } from '../utils/Socket';

const log = (msg = '') =>
  console.log(`  %cACTION:: %c${msg}`, 'color: orange', 'color: gold');

export const callSocketActions = {
  agentLogin: (agentCode) => {
    log('@@SOCKET_EMIT: agentLogin');
    // if (callerSocket)
    //   callerSocket.emit('ACTION', {
    //     CMD: EMIT_CMD.AGENT_LOGIN,
    //     AGENT_CODE: agentCode,
    //   });
  },

  agentLogout: () => {
    log('@@SOCKET_EMIT: agentLogout');
    if (callerSocket)
      callerSocket.emit('ACTION', { CMD: EMIT_CMD.AGENT_LOGOUT });
  },

  qLogin: () => {
    log('@@SOCKET_EMIT: qLogin');
    if (callerSocket)
      callerSocket.emit('ACTION', { CMD: EMIT_CMD.QUEUE_LOGIN });
  },

  qLogout: () => {
    log('@@SOCKET_EMIT: qLogout');
    if (callerSocket)
      callerSocket.emit('ACTION', { CMD: EMIT_CMD.QUEUE_LOGOUT });
  },

  qPause: (reason) => {
    log('@@SOCKET_EMIT: qPause');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.QUEUE_READY,
        PAUSED: 'N',
        REASON: reason ?? '13',
      });
  },

  qUnpause: () => {
    log('@@SOCKET_EMIT: qUnpause');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.QUEUE_READY,
        PAUSED: 'Y',
        REASON: '0',
      });
  },

  answerCall: () => {
    log('@@SOCKET_EMIT: answerCall');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.ANSWER });
  },

  hangupCall: () => {
    log('@@SOCKET_EMIT: hangupCall');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.HANGUP });
  },

  outCall: (telNumber) => {
    log('@@SOCKET_EMIT: outCall');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.OUTCALL,
        TEL_NUMBER: telNumber,
      });
  },

  transfer: (receiver) => {
    log('@@SOCKET_EMIT: transfer');
    if (callerSocket)
      callerSocket.emit('ACTION', {
        CMD: EMIT_CMD.TRANSFER,
        RECEIVER: receiver,
      });
  },

  unTransfer: () => {
    log('@@SOCKET_EMIT: unTransfer');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.UNTRANSFER });
  },

  pickupTransfer: () => {
    log('@@SOCKET_EMIT: pickupTransfer');
    if (callerSocket) callerSocket.emit('ACTION', { CMD: EMIT_CMD.PICKUP });
  },

  playIVRScenario: (flow) => {
    log('@@SOCKET_EMIT: playIVRScenario');
    if (callerSocket)
      callerSocket.emit('ACTION', { CMD: EMIT_CMD.IVR_ROUTING, FLOW: flow });
  },
};
