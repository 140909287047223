import withPrivate from '@hoc/withPrivate';
import consultationConfig from './private/consultationConfig';

const privateRouteConfig = [...consultationConfig].map((item) => {
  const Component = withPrivate(item.element);

  return {
    ...item,
    element: <Component />,
  };
});

export default privateRouteConfig;
