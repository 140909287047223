import { Table } from 'antd';

import { toDateFormat, toPhoneNumberFormat } from '@helper/formatHelper';
import useConsultationStore from '@store/useConsultationStore';

function TabFamliy() {
  const family = useConsultationStore((state) => state.currentMember?.family);

  const columns = [
    { title: '회원 ID', dataIndex: 'memberId' },
    { title: '회원 이름', dataIndex: 'memberName' },
    {
      title: '구분',
      dataIndex: ['memberTypes', '0', 'memberTypeNm'],
      render: (_, row) =>
        `${row.memberTypes[0].memberTypeNm}${
          row.courseNm ? ` / ${row.courseNm} ${row.gradeNm}` : ''
        }`,
    },
    {
      title: '상태',
      dataIndex: 'memberGradeNm',
      render: (_, row) => `${row.memberGradeNm} / ${row.memberStateNm}`,
    },
    { title: '전화번호', dataIndex: 'mobile', render: toPhoneNumberFormat },
    {
      title: '가입날짜',
      dataIndex: 'accountCreatedAt',
      render: (date) => toDateFormat(date, 'YYYY-MM-DD'),
    },
  ];

  return (
    <Table
      rowKey="memberId"
      total={family?.length || 0}
      dataSource={family || []}
      columns={columns}
      pagination={false}
    />
  );
}

export default TabFamliy;
