import { create } from 'zustand';
import { immer, logger } from '@store/index';

const initialState = {
  latestAgentCallEvent: '',
  call: {
    customerPhoneNumber: '',
    cid: '',
    agentNumber: '',
    eventType: '',
    ivr: '',
    startTime: '',
    endTime: '',
  },
  device: {
    status: '',
  },
  queue: {
    status: 'notReadyToQueueConnect',
    connected: false,
  },
  error: { value: 0, label: '정상' },
};

const state = (set) => ({
  ...initialState,
  setCallSocketState: (props) => {
    if (!props) return;
    if (props.some((value) => value === null)) return;
    set(...props);
  },
  reset: () => set(initialState, false, '@@CALL :: [RESET}'),
});

export const useCallSocketStore = create(immer(logger(state)));
