import { Navigate } from 'react-router-dom';
import { Redirect } from '@components/common';

import { LoginPage, NotFoundPage } from '@pages';

const publicRoutingConfig = [
  {
    path: 'login',
    element: <LoginPage />,
    option: {
      name: '로그인',
    },
  },
  {
    path: '/404',
    element: <NotFoundPage />,
    option: {
      name: '에러',
    },
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
];

export default publicRoutingConfig;
