import { isEmpty } from 'lodash';

/* eslint-disable class-methods-use-this */
class Storage {
  _keepLogin = 'true';

  // 잠시 로그인세션을 들고 있음
  holder = {};

  constructor() {
    this._keepLogin = localStorage.getItem('keepLogin');
  }

  setToken(accessToken, refreshToken) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  }

  clearToken() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
  }

  getToken() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    return {
      accessToken,
      refreshToken,
    };
  }

  hasSession() {
    const { accessToken, refreshToken } = this.getToken();
    return !isEmpty(accessToken) && !isEmpty(refreshToken);
  }

  getKeepLogin() {
    return this._keepLogin === 'true';
  }
}

const storage = new Storage();
export default storage;
