import io from 'socket.io-client';

import { isDevelopment } from '@utils/systemPhaseUtils';
import {
  STORAGE_KEY_FOR_WORKPLACE,
  WORKPLACE_OPTIONS,
} from '@constants/workplace';
import { ENV_VAR } from '../data/constants';

const { ACCOUNT_KEY, OFFICE_IP, REMOTE_IP } = ENV_VAR;
const socketOptions = {
  reconnection: false,
  transports: ['websocket'],
  query: { account: ACCOUNT_KEY },
};

class Socket {
  _callerInstance = null;
  _monitorInstance = null;

  get callerSocket() {
    return this._callerInstance != null
      ? this._callerInstance
      : this.initSocket('call');
  }

  get monitorSocket() {
    return this._monitorInstance != null
      ? this._monitorInstance
      : this.initSocket('monitor');
  }

  initSocket(type) {
    if (!['call', 'monitor'].some((value) => value === type)) return null;
    let protocol = 'http';
    let port = type === 'call' ? '8081' : '8082';
    let socketIP = REMOTE_IP;

    if (!isDevelopment()) {
      const SOCKET_IP =
        localStorage.getItem(STORAGE_KEY_FOR_WORKPLACE) ===
        WORKPLACE_OPTIONS.OFFICE
          ? OFFICE_IP
          : REMOTE_IP;

      port = type === 'call' ? '8083' : '8084';
      socketIP = SOCKET_IP;
      protocol = 'https';
    }

    const socketURI = `${protocol}://${socketIP}:${port}`;

    switch (type) {
      case 'call':
        this._callerInstance = io(socketURI, socketOptions);
        return this._callerInstance;
      case 'monitor':
        this._monitorInstance = io(socketURI, socketOptions);
        return this._monitorInstance;
      default:
        return null;
    }
  }

  connectSockets() {
    if (this._callerInstance && this._callerInstance.disconnected)
      this._callerInstance.connect();
    if (this._monitorInstance && this._monitorInstance.disconnected)
      this._monitorInstance.connect();
  }

  disconnectSockets() {
    if (this._callerInstance) this._callerInstance.disconnect();
    if (this._monitorInstance) this._monitorInstance.disconnect();
  }
}

const socket = new Socket();
export const connectSockets = socket.connectSockets.bind(socket);
export const disconnectSockets = socket.disconnectSockets.bind(socket);
export const { callerSocket, monitorSocket } = socket;
