const SYSTEM_PHASE = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  LOCAL: 'local',
};

export const getSystemPhase = () => {
  if (!process.env.REACT_APP_MODE) return SYSTEM_PHASE.LOCAL;
  return process.env.REACT_APP_MODE;
};

export const isLocal = () =>
  !process.env.REACT_APP_MODE ||
  process.env.REACT_APP_MODE === SYSTEM_PHASE.LOCAL;

export const isStaging = () =>
  process.env.REACT_APP_MODE === SYSTEM_PHASE.STAGING;

export const isDevelopment = () =>
  process.env.REACT_APP_MODE === SYSTEM_PHASE.DEVELOPMENT ||
  isLocal() ||
  isStaging();

export const isProduction = () =>
  process.env.REACT_APP_MODE === SYSTEM_PHASE.PRODUCTION;
