import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import routingConfig from '@config/rootRouteConfig';

import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const router = createBrowserRouter(routingConfig());

const root = ReactDOM.createRoot(document.getElementById('root'));

dayjs.extend(customParseFormat);
dayjs.locale('ko');

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    staleTime: 1000 * 20,
    refetchOnWindowFocus: false,
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {process.env.REACT_APP_MODE === 'development' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </React.StrictMode>,
);
