import dayjs from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from '@store/index';
import { CATS_QUEUE } from '../data/constants';

const STORAGE_KEY_FOR_MONITOR_SOCKET = 'DANBI_CS_CENTER::MONITOR_SOCKET';

const initialState = {
  baseDate: dayjs().format('YYYY-MM-DD'),
  winkQueue: {},
  winkBooksQueue: {},
  catsQueue: {},
  testQueue: {},
  agents: {},
  abandonedCalls: [],
  joinedCalls: [],
  catsAgent: {}, // TODO temporary on cats launch
  catsJoinedCalls: [], // TODO temporary on cats launch
};

const state = (set) => ({
  ...initialState,
  setMonitorSocketState: (props) => {
    if (!props) return;
    if (props.some((value) => value === null)) return;
    set(...props);
  },
  reset: () => set(initialState, false, '@@MONITOR :: [RESET}'),
});

export const useMonitorSocketStore = create(
  persist(immer(state), {
    name: STORAGE_KEY_FOR_MONITOR_SOCKET,
  }),
);

export const catsAgentSelector = (monitorState) => {
  const agents = {};
  Object.entries(monitorState.agents).forEach(([key, value]) => {
    if (value.eventMember && value.eventMember.queue === CATS_QUEUE) {
      agents[key] = value;
    }
  });
  return agents;
};
