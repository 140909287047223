import { Modal, Button, Descriptions, Table } from 'antd';

import { useGetDeliveryStatusHistory } from '@api';
import { useOverlayContext } from '@context/OverlayContext';
import { toDateFormat } from '@helper/formatHelper';
import { TagDeliveryStatus } from '@components/ui';

import ModalContainer from './base/ModalContainer';

function ModalDeliveryStatus({ id }) {
  const { closeModal } = useOverlayContext();
  const { data: delivery } = useGetDeliveryStatusHistory({ id });

  const handleClose = () => {
    closeModal();
  };

  return (
    <ModalContainer>
      <Modal
        open
        title="배송상태 변경이력"
        closable={false}
        footer={<Button onClick={() => handleClose()}>닫기</Button>}
        width="60%"
      >
        <Descriptions bordered className="my-5">
          <Descriptions.Item
            label="배송주문번호(ID)"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            <div className="w-fit">{id}</div>
          </Descriptions.Item>
        </Descriptions>
        <Table
          rowKey="createdDate"
          total={delivery?.length || 0}
          dataSource={delivery || []}
          columns={[
            {
              title: '상태',
              dataIndex: 'status',
              render: (_, { status }) => <TagDeliveryStatus status={status} />,
            },
            {
              title: '일자',
              dataIndex: 'createdDate',
              render: (createdDate) => toDateFormat(createdDate),
            },
            { title: '작업자', dataIndex: 'processor' },
            { title: '사유', dataIndex: 'reason', width: 250 },
          ]}
          pagination={false}
        />
      </Modal>
    </ModalContainer>
  );
}

export default ModalDeliveryStatus;
